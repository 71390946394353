<template>
	<section class="container container-l flex flex-col mx-auto">
		<!-- <figure
			class="recent-work-img shadow-full overflow-hidden mt-8 picture-project"
			v-if="project.main_image"
		>
			<img
				class="w-full h-full object-cover"
				:src="project.main_image"
				alt=""
			/>
		</figure> -->
		<figure
			class="recent-work-img shadow-full overflow-hidden mt-8 picture-project"
			v-if="project.cover_image"
		>
			<img
				class="w-full h-full object-cover"
				:src="project.cover_image"
				alt=""
			/>
		</figure>

		<div class="flex flex-wrap xs:flex-nowrap justify-between my-12">
			<span class="text-2xl">{{ project.project_name }}</span>
			<span>{{ $t("common.with") }}: {{ project.company_name }}</span>
		</div>
		<div>
			<span class="text-2xl">{{ $t("common.benefits") }}</span>

			<ul class="flex flex-wrap mt-8">
				<li
					class="w-full xs:w-1/2 lg:w-1/3 px-2 mb-3 flex items-center"
					v-for="benefit in project.benefits"
					:key="benefit.id"
				>
					<div class="w-3 h-3 rounded-full bg-secondary-600 me-4"></div>
					<span> {{ benefit.text }}</span>
				</li>
				<!-- <li class="w-full xs:w-1/2 lg:w-1/3 px-2 mb-3 flex items-center">
					<div class="w-3 h-3 rounded-full bg-secondary-600 me-4"></div>
					<span> The project’s main strategic decision</span>
				</li>
				<li class="w-full xs:w-1/2 lg:w-1/3 px-2 mb-3 flex items-center">
					<div class="w-3 h-3 rounded-full bg-secondary-600 me-4"></div>
					<span> The project’s main strategic decision</span>
				</li>
				<li class="w-full xs:w-1/2 lg:w-1/3 px-2 mb-3 flex items-center">
					<div class="w-3 h-3 rounded-full bg-secondary-600 me-4"></div>
					<span> The project’s main strategic decision</span>
				</li>
				<li class="w-full xs:w-1/2 lg:w-1/3 px-2 mb-3 flex items-center">
					<div class="w-3 h-3 rounded-full bg-secondary-600 me-4"></div>
					<span> The project’s main strategic decision</span>
				</li>
				<li class="w-full xs:w-1/2 lg:w-1/3 px-2 mb-3 flex items-center">
					<div class="w-3 h-3 rounded-full bg-secondary-600 me-4"></div>
					<span> The project’s main strategic decision</span>
				</li> -->
			</ul>
		</div>

		<div class="flex flex-wrap">
			<div class="w-full sm:w-1/3 mt-20 text-center sm:border-r-2 px-11">
				<span class="">{{ $t("common.concept") }}</span>
				<p class="mt-6">
					{{ project.concept }}
				</p>
			</div>

			<div class="w-full sm:w-1/3 mt-20 text-center sm:border-r-2 px-11">
				<span class="">{{ $t("common.problem") }}</span>
				<p class="mt-6">
					{{ project.problem }}
				</p>
			</div>

			<div class="w-full sm:w-1/3 mt-20 text-center px-11">
				<span class="">{{ $t("common.solution") }}</span>
				<p class="mt-6">
					{{ project.solution }}
				</p>
			</div>
		</div>
		<div class="my-20 text-center" v-if="tecnologies.length">
			<h2>{{ $t("common.doneWith") }}</h2>
			<div>
				<tecnologies :tecnologies="tecnologies" />
				<!-- <img :src="project.technologies[0].image" alt="" /> -->
			</div>
		</div>
		<div>
			<figure>
				<img :src="project.presintation.image" alt="" />
			</figure>
		</div>
	</section>
</template>

<script>
import Tecnologies from "../components/Tecnologies.vue";
export default {
	components: { Tecnologies },
	data() {
		return {
			project: {
				id: 1,
				project_name: "7fza Quran Social media",
				company_name: " Charity Team",
				slug: "officiis-voluptatem-214371",
				main_image: "images/7fza-cover.png",
				cover_image: "images/7fza-cover.png",
				client: {
					id: 6,
					name: {
						en: "Nathen White"
					},
					info: {
						en:
							"Neque incidunt hic illo voluptate. Qui adipisci eos rerum sapiente accusantium rerum. Magnam voluptatem rem vel accusantium."
					},
					logo: [
						{
							id: 6,
							mime_type: "image/png",
							path:
								"http://localhost/storage/Client/6/images/ca0b7decf6ecefac8b830fc313b5cfb4.png",
							custom_properties: {
								data: null
							}
						}
					]
				},
				problem:
					"7fza solves the problem of the scarcity and dispersion of information and the difficulty of reaching the providers of the sciences of the Holy Qur’an and Arabic language.",
				solution:
					"We presented a practical solution that combines simplicity and aesthetics with a unique design that draws its vocabulary from Islamic art without focusing on the image at the expense of ease of access to information. Through the platform, we were able to link the seeker of knowledge and the provider of this knowledge.",
				concept:
					"The project’s main strategic concept depended on the social media apps model. We tried to combine seriousness and fun during the learning process.",
				technologies: {
					back_end: [
						{
							id: 3,
							image: "images/phpstorm-logo.png",
							name: "PhpStorm"
						},
						{
							id: 4,
							image: "images/laravel-logo.png",
							name: "PHP Laravel"
						}
						// { id: 5, midea: "@/assets/images/Hosting.svg", name: "photo shop" },
						// { id: 6, midea: "@/assets/images/Hosting.svg", name: "illestrator" }
					],
					front_end: [
						{ id: 7, image: "images/vue-logo.svg", name: "Vue js" },
						{
							id: 8,
							image: "images/visual-logo.jpeg",
							name: "Visual studio code"
						}
						// { id: 9, midea: "@/assets/images/Hosting.svg", name: "photo shop" },
						// {
						// 	id: 10,
						// 	midea: "@/assets/images/Hosting.svg",
						// 	name: "illestrator"
						// }
					],
					// mobile: [
					// 	{ id: 11, midea: "@/assets/images/Hosting.svg", name: "3d max" }
					// ],
					design: [
						{ id: 11, image: "images/adobe-xd-logo.png", name: "Adobe XD" }
					]
				},
				benefits: [
					{
						id: 1,
						text:
							"Gathering all interested in Quran and Arabic language sciences in one place"
					},
					{
						id: 2,
						text:
							"Attracting more people to study the Quran and Arabic language."
					},
					{
						id: 3,
						text:
							"Give Memorizers of the Holy Quran higher goals as a motivation."
					},
					{
						id: 4,
						text:
							"Give those talented Quran readers the opportunity to be heard"
					}
				],
				presintation: {
					image: "images/7fza-pre.png"
				}
			},
			tecnologies: []
		};
	},
	mounted() {
		// console.log(this.project.technologies);
		// this.project.technologies.map(item => {
		// 	item.map(element => {
		// 		this.tecnologies.push(element);
		// 		console.log(this.tecnologies);
		// 	});
		// });
		for (const [key, value] of Object.entries(this.project.technologies)) {
			console.log(`${key}: ${value}`);
			value.map(element => {
				this.tecnologies.push(element);
				// console.log(this.tecnologies);
			});
		}
		console.log(this.tecnologies);
	}
};
</script>
<style scoped>
.picture-project {
	box-shadow: 0px 3px 16px rgba(#000000, 0.16);

	border-radius: 0 75px 0 75px;
}
</style>
